<template>
  <div class="tablePlan" v-if="table.planning && table.planning.length">
    <div class="table">
      <div v-if="showLoader" class="table__loader">
        <Loader />
      </div>
      <div class="table__body">
        <div class="header">
          <div class="fio">ФИО</div>
          <div class="position">Должность</div>
          <div
            class="item"
            v-for="(item, index) in table.planning[0].base_values"
            :key="index"
          >
            <div v-if="item.type === 'day'">{{ item.date }}</div>
            <div v-if="item.type === 'month'">{{ item.date.slice(0, -3) }}</div>
            <div v-if="item.type === 'quarter'">{{ item.date }}</div>
            <div v-if="item.type === 'year'">{{ item.date }}</div>
          </div>
          <div class="total">Итого</div>
          <div v-if="planTab !== 0" class="deviation">Откл</div>
        </div>

        <div class="body">
          <ListItem
            ref="ListItem"
            v-for="user in getTable"
            :key="user.id"
            :user="user"
            :planTab="planTab"
            :usersProjectItemsChecked="usersProjectItemsChecked"
            :mouseIsPress="mouseIsPress"
            @usersProjectItemsCheckedChange="usersProjectItemsCheckedChange"
            @addProjectForUser="addProjectForUser"
            @addProjectForAbstract="addProjectForAbstract"
            @openOrderSpecialist="openOrderSpecialist"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Loader from '@/components/UI/Loader.vue';
import ListItem from './Table/ListItem.vue';

export default {
  name: 'Table',
  components: {
    ListItem,
    Loader,
  },
  props: {
    usersProjectItemsChecked: {
      type: Array,
      default: () => [],
    },
    planTab: {
      type: Number,
      default: 0,
    },
    mouseIsPress: {
      type: Boolean,
      default: false,
    },
    showLoader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('plan', ['table']),
    getTable() {
      const abstract = {
        name: 'Abstract',
        id: 'Abstract',
        deviation: null,
        total: { plan: null, fact: null },
        base_values: this.table.planning[0].base_values,
        projects: this.table.abstract.projects.map((el) => ({
          ...el,
          cells: new Array(this.table.planning[0].base_values.length),
        })),
      };
      return [abstract].concat(this.table.planning);
    },
  },
  methods: {
    addProjectForUser(obj) {
      this.$emit('addProjectForUser', obj);
    },
    addProjectForAbstract(obj) {
      this.$emit('addProjectForAbstract', obj);
    },
    openOrderSpecialist(user) {
      this.$emit('openOrderSpecialist', user);
    },
    closeOrderSpecialist() {
      this.$emit('openOrderSpecialist');
    },
    usersProjectItemsCheckedChange(obj) {
      const user = this.usersProjectItemsChecked.find((el) => el.id === obj.id);
      if (user) {
        const projectInd = user.projects.findIndex(
          (p) => p.id === obj.projectId,
        );
        if (projectInd > -1) {
          const cellInd = user.projects[projectInd].cells.findIndex(
            (el) => el.date === obj.date,
          );
          if (cellInd > -1) {
            user.projects[projectInd].cells.splice(cellInd, 1);
          } else {
            user.projects[projectInd].cells.push({ date: obj.date });
          }
        } else {
          user.projects = [{ id: obj.projectId, cells: [{ date: obj.date }] }];
        }
      } else {
        this.usersProjectItemsChecked.splice(0, 1, {
          id: obj.id,
          position: obj.position,
          projects: [{ id: obj.projectId, cells: [{ date: obj.date }] }],
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/assets/styles/variables';
* {
  font-weight: 500;
  font-size: 12px;
  font-family: sans-serif;
}
.tablePlan {
  margin-top: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;

  .table {
    width: max-content;
    user-select: none;
    position: relative;
    overflow-y: hidden;
    height: 100%;
    width: 100%;

    &__body {
      height: 100%;
      overflow-y: auto;
    }

    &__loader {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: #ffffff;
      opacity: 0.8;
      z-index: 10;
      display: flex;
      user-select: none;
    }
  }

  .header {
    position: sticky;
    top: 0;
    z-index: 9;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: $main-background;
    }
    > div {
      background: #eff1f2;
      &:first-child {
        border-top-left-radius: 12px;
      }
      &:last-child {
        border-top-right-radius: 12px;
      }
      border-top: 1px solid #d6d6d6;
    }
  }

  .header,
  .elem {
    display: flex;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #d6d6d6;
      border-bottom: 1px solid #d6d6d6;
    }
    .fio {
      flex-direction: column;
      align-items: start;
      padding: 0 10px;
      width: 300px;
      min-width: 300px;
      border-left: 1px solid #d6d6d6;
      .inner {
        display: flex;
        width: 100%;
        .arrow {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .description {
          width: calc(100% - 30px);
        }
      }
      .name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
      }
      .outsourcing {
        font-size: 12px;
        color: #aaaaaa;
      }
    }
    .position {
      justify-content: start;
      padding: 0 10px;
      width: 120px;
      min-width: 120px;
    }
    .item {
      height: 50px;
      width: 75px;
      min-width: 75px;
      text-align: center;
    }
    .total {
      width: 75px;
      min-width: 75px;
    }
    .deviation {
      width: 75px;
      min-width: 75px;
    }
  }
}
</style>
