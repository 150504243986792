var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user && _vm.user.name)?_c('div',[_c('div',{staticClass:"elem",class:{ 'elem--sub': _vm.isSubItem }},[_c('div',{staticClass:"fio"},[_c('div',{staticClass:"inner",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.isSubItem = !_vm.isSubItem}}},[_c('div',{staticClass:"arrow"},[_c('v-icon',{staticClass:"mr-2 chevron",style:({
              transform: _vm.isSubItem ? 'rotate(180deg)' : 'rotate(0deg)',
            })},[_vm._v("mdi-chevron-down")])],1),_c('div',{staticClass:"description"},[_c('div',{staticClass:"name",style:({ 'font-weight': _vm.isSubItem ? '600' : '500' })},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]),(_vm.user.is_outsource)?_c('div',{staticClass:"outsourcing"},[_vm._v("Аутстаф")]):_vm._e()]),_c('v-menu',{key:"text",staticClass:"dots",attrs:{"rounded":"lg","offset-y":""}}),(_vm.isAddProjectModal)?_c('AddProjectModalTwoCalendars',{attrs:{"isAddProjectModal":_vm.isAddProjectModal},on:{"addProjectModal":_vm.addProjectModal,"addProject":_vm.addProject}}):_vm._e(),(_vm.isOpenAddAppointEmployees)?_c('AddAppointEmployees',{attrs:{"isAddProjectModal":_vm.isOpenAddAppointEmployees,"currentAppointEmployeesData":_vm.currentAppointEmployeesData},on:{"addProjectModal":_vm.addAppointEmployees,"addProject":_vm.addProjectForAbstract}}):_vm._e()],1)]),_c('div',{staticClass:"position",style:({ 'font-weight': _vm.isSubItem ? '600' : '500' })},[_vm._v(" "+_vm._s(_vm.user.position)+" ")]),(_vm.user.id !== 'Abstract')?_vm._l((_vm.user.base_values),function(item,index){return _c('div',{key:index,staticClass:"item",style:({
          'font-weight': _vm.isSubItem ? '600' : '500',
          background: _vm.constructGradient(item),
        })},[(item.data.type === 'work')?_c('div',[_vm._v(" "+_vm._s(_vm.planTab !== 0 ? ((item.data.plan) + " / " + (item.data.fact)) : ("" + (item.data.plan)))+" ")]):(item.data.type === 'vacation')?_c('div',[_vm._v("🌴")]):(item.data.type === 'dayoff')?_c('div',[_vm._v("🕺")]):(item.data.type === 'sick')?_c('div',[_vm._v("🤕")]):_vm._e()])}):_vm._l((_vm.user.base_values),function(item,index){return _c('div',{key:index,staticClass:"item"})}),_c('div',{staticClass:"total",style:({
        'font-weight': _vm.isSubItem ? '600' : '500',
      })},[(_vm.user.id !== 'Abstract')?_c('span',[_vm._v(" "+_vm._s(_vm.planTab !== 0 ? ((_vm.user.total.plan) + " / " + (_vm.user.total.fact)) : ("" + (_vm.user.total.plan)))+" ")]):_vm._e()]),(_vm.planTab !== 0)?_c('div',{staticClass:"deviation",style:({ 'font-weight': _vm.isSubItem ? '600' : '500' })},[(_vm.user.id !== 'Abstract')?_c('span',[_vm._v(" "+_vm._s(_vm.user.deviation)+"% ")]):_vm._e()]):_vm._e()],2),(_vm.isSubItem)?_c('div',_vm._l((_vm.user.projects),function(project,indexProjects){return _c('div',{key:indexProjects,staticClass:"elem elemProject",class:{ 'elem--sub': _vm.isSubItem }},[_c('div',{staticClass:"fio"},[_c('div',[_vm._v(_vm._s(project.name))]),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var attrs = ref.attrs;
      var on = ref.on;
return [(_vm.user.id === 'Abstract')?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteEmployee({
                      project_id: project.id,
                      user_id: _vm.user.id,
                      position: _vm.user.position,
                    })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Удалить сотрудника с проекта")])])]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","dense":""},on:{"click":function($event){return _vm.openOrderSpecialist({
                  project: project,
                  position: _vm.user.position,
                })}}},[_c('v-list-item-title',[_vm._v("Заявка на специалиста")])],1),_c('v-list-item',{attrs:{"link":"","dense":""},on:{"click":function($event){return _vm.openAppointEmployees({
                  project: project,
                })}}},[_c('v-list-item-title',[_vm._v("Назначить сотрудника")])],1)],1)],1)],1),(_vm.user.id === 'Abstract')?_c('div',{staticClass:"position"},[_vm._v(" "+_vm._s(project.position)+" "),_c('br'),_vm._v(" ("+_vm._s(project.value)+") ")]):_vm._e(),(_vm.user.id !== 'Abstract')?_c('div',{staticClass:"position"},[_vm._v(" "+_vm._s(project.position)+" ")]):_vm._e(),(_vm.user.id !== 'Abstract')?_vm._l((project.cells),function(itemProject,indexProject){return _c('div',{key:indexProject,staticClass:"item itemProject",class:{
            itemProjectChecked: _vm.isItemProjectChecked(project, itemProject),
          },on:{"click":function($event){return _vm.checkedItemProject(project, itemProject)},"mousemove":function($event){return _vm.checkedItemProjectMove($event, project, itemProject)}}},[(itemProject.data.type === 'work')?_c('div',[_vm._v(" "+_vm._s(_vm.planTab !== 0 ? ((itemProject.data.plan) + " / " + (itemProject.data.fact)) : ("" + (itemProject.data.plan)))+" ")]):(itemProject.data.type === 'vacation')?_c('div',[_vm._v("🌴")]):(itemProject.data.type === 'dayoff')?_c('div',[_vm._v("🕺")]):(itemProject.data.type === 'sick')?_c('div',[_vm._v("🤕")]):_vm._e()])}):_vm._l((project.cells),function(itemProject,indexProject){return _c('div',{key:indexProject,staticClass:"item"})}),_c('div',{staticClass:"total"},[(_vm.user.id !== 'Abstract')?_c('span',[_vm._v(" "+_vm._s(_vm.planTab !== 0 ? ((project.total.plan) + " / " + (project.total.fact)) : ("" + (project.total.plan)))+" ")]):_vm._e()]),(_vm.planTab !== 0)?_c('div',{staticClass:"deviation"},[(_vm.user.id !== 'Abstract')?_c('span',[_vm._v(" "+_vm._s(project.deviation)+"% ")]):_vm._e()]):_vm._e()],2)}),0):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }