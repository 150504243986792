<template>
  <div class="order">
    <div class="top">
      <div class="left">
        <div
          class="back"
          @click="closeEdit"
        >
          <v-icon
            color="#1976D2"
            class="mr-1"
          >mdi-arrow-left</v-icon>
          Назад
        </div>
        <div class="title">Заявка на специалиста</div>
      </div>

      <div class="right">
        <v-btn
          class="edit-project__close"
          icon
          plain
          @click="closeEdit"
        >
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      </div>
    </div>

    <div class="content">
      <div class="left">
        <div class="project">
          <div class="title">1. Проект *</div>
          <v-select
            :items="getFilterProjects"
            label="Выберите проект"
            outlined
            rounded
            hide-details
            v-model="orderSpecialist.project"
            item-value="id"
            item-text="value"
            class="rounded-lg mt-3"
          ></v-select>

        </div>

        <div class="time">
          <div class="title">2. Период работы сотрудника на проекте *</div>

          <div class="dates">
            <div class="date">
              <DatePicker
                placeholder="Начало проекта"
                :value="order.date_start"
                @changeDate="changeDateFirst"
              />
            </div>
            <div class="date">
              <DatePicker
                placeholder="Окончание проекта"
                :value="order.date_end"
                @changeDate="changeDateSecond"
              />
            </div>
          </div>

          <v-radio-group v-model="order.radioGroupEmployee">
            <v-radio
              v-for="(n, index) in radioGroupEmployees"
              :key="index"
              :label="`${n.text}`"
              :value="n.value"
            ></v-radio>
          </v-radio-group>
        </div>

        <div class="specialization">
          <div class="title">3. Специализация и компетенции *</div>
          <v-select
            :items="getFilterPositions"
            label="Специализация сотрудника"
            outlined
            rounded
            hide-details
            v-model="orderSpecialist.position"
            item-value="value"
            item-text="value"
            class="rounded-lg mt-3"
          ></v-select>

          <v-select
            :items="filterLevels"
            label="Уровень специалиста"
            outlined
            rounded
            hide-details
            v-model="order.level"
            item-value="value"
            item-text="text"
            class="rounded-lg mt-3"
          ></v-select>

          <v-text-field
            label="Навык, например, HTML"
            class="rounded-lg mt-3"
            outlined
            v-model="skill"
            @change="changeSearchSkills"
            rounded
            hide-details
            height="50"
          ></v-text-field>

          <div class="orderSkills">
            <transition-group
              name="list"
              tag="div"
            >
              <span
                v-for="(skill,index) in order.skills"
                v-bind:key="index + 1"
                class="list-item"
              >
                <span>
                  {{skill}}
                </span>

                <v-icon
                  class="ml-1"
                  @click="deleteSkill(skill)"
                  size="18"
                >mdi-close-circle</v-icon>
              </span>
            </transition-group>
          </div>

          <div class="skills rounded-lg">
            <div class="tit">
              Рекомендуемые навыки
            </div>

            <div class="allSkills">
              <div
                class="skill rounded-lg"
                v-for="(skill,index) in allSkills"
                :key="index"
                @click="changeSearchSkillsSelect(skill)"
                :class="{'skillActive': isSkillIsActive(skill)}"
              >
                {{skill}}
              </div>

            </div>
          </div>
        </div>

        <div class="price">
          <div class="title">4. Стоимость сотрудника *</div>

          <div class="prices">
            <div class="block">
              <PayTime
                @changePayTime="changePayTimeFrom"
                :value="String(order.paymentFrom)"
                label="Стоимость работы в час, ₽ *"
              />
            </div>

            <div class="block">
              <PayTime
                @changePayTime="changePayTimeTo"
                :value="String(order.paymentTo)"
                label="Стоимость работы в час, ₽ *"
                placeholder="2500"
              />
            </div>
          </div>
        </div>

        <div class="comments">
          <div class="title">5. Ваш комментарий *</div>

          <v-textarea
            outlined
            v-model="order.description"
            label="Описание"
            hide-details
            class="rounded-lg"
            background-color="white"
            no-resize
            height="70"
          ></v-textarea>
        </div>

        <v-btn
          color="black"
          height="52"
          width="210"
          weight="800"
          dark
          class="mt-4 radius-root"
        >
          Создать заявку
        </v-btn>
      </div>

      <div class="right">
        <ShowHideText :text="projectDescription" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ShowHideText from '@/components/Dropdowns/ShowHideText.vue';
import DatePicker from '@/components/UI/DatePicker.vue';
import PayTime from '@/components/Inputs/PayTime.vue';

export default {
  name: 'OrderSpecialist',
  components: {
    ShowHideText,
    DatePicker,
    PayTime,
  },
  props: {
    orderSpecialist: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      showProjectDecription: true,
      projectDescription: '1\n3\n5 В мобильном дизайне надо избавляться от всего,'
        + ' что не имеет первостепенной необходимости. Снижение беспорядка улучшит понимание.'
        + ' Простое правило: В мобильном дизайне надо избавляться от всего, что не имеет первостепенной необходимости.'
        + ' Снижение беспорядка улучшит понимание. Простое правило: В мобильном дизайне надо избавляться от всего,'
        + ' что не имеет первостепенной необходимости. Снижение беспорядка улучшит понимание. '
        + 'Простое правило: В мобильном дизайне надо избавляться от всего, что не имеет первостепенной необходимости.'
        + ' Снижение беспорядка улучшит понимание. Простое правило: В мобильном дизайне надо избавляться от всего,'
        + ' что не имеет первостепенной необходимости. Снижение беспорядка улучшит понимание. Простое правило:',
      radioGroupEmployees: [
        { text: 'Аутстаф сотрудник', value: 1 },
        { text: 'Cотрудник в штат', value: 2 },
        { text: 'Аутстаф и в штат', value: 3 },
      ],
      filterLevels: [
        { text: 'Junior', value: 1 },
        { text: 'Middle', value: 2 },
        { text: 'Senior', value: 3 },
      ],
      skill: '',
      allSkills: ['HTML', 'CJM', 'User Story', 'Research', 'UX analutics', 'Wireframes', 'Figma'],
      order: {
        date_start: '',
        date_end: '',
        radioGroupEmployee: 1,
        paymentFrom: '',
        paymentTo: '',
        description: '',
        level: '',
        skills: [],
      },
    };
  },
  computed: {
    ...mapState(['filters']),
    getFilterProjects() {
      return this.filters?.projects || [];
    },
    getFilterPositions() {
      return this.filters?.positions || [];
    },
  },
  methods: {
    deleteSkill(val) {
      const index = this.order.skills.findIndex((el) => el === val);
      this.order.skills.splice(index, 1);
    },
    isSkillIsActive(val) {
      return !!this.order.skills.find((el) => el === val);
    },
    isSkillIsActiveContain(val) {
      return !!this.allSkills.find((el) => el === val);
    },
    changeSearchSkills(val) {
      const str = val.trim();
      if (!this.order.skills.find((el) => el === str) && str) this.order.skills.push(str);
      this.skill = '';
    },
    changeSearchSkillsSelect(str) {
      if (!this.order.skills.find((el) => el === str)) this.order.skills.push(str);
      else {
        const index = this.order.skills.findIndex((el) => el === str);
        this.order.skills.splice(index, 1);
      }
    },
    closeEdit() {
      this.$emit('closeOrderSpecialist');
    },
    changeDateFirst(val) {
      this.order.date_start = val;
    },
    changeDateSecond(val) {
      this.order.date_end = val;
    },
    changePayTimeFrom(val) {
      this.order.paymentFrom = val.payment;
    },
    changePayTimeTo(val) {
      this.order.paymentTo = val.payment;
    },
  },
};
</script>

<style scoped lang="scss">
.order {
  background: #fff;
  width: 100%;

  .top {
    display: flex;
    height: 100px;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      flex-direction: column;
      .back {
        user-select: none;
        cursor: pointer;
        color: #1976d2;
        display: flex;
        opacity: 0.8;
        align-items: center;
        &:hover {
          opacity: 1;
        }
      }
      .title {
        margin-top: 10px;
        font-weight: bold !important;
        font-size: 28px !important;
      }
    }
  }
  .content {
    background: #f9f9f9;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    border-radius: 16px;
    margin-top: 30px;
    padding: 30px;
    display: flex;

    .left,
    .right {
      width: 50%;
    }
    .right {
      margin-left: 40px;
      margin-top: 33px;
    }

    .project,
    .time,
    .specialization,
    .price,
    .comments {
      .title {
        font-weight: 600 !important;
        font-size: 14px !important;
      }
    }
    .time,
    .specialization,
    .price,
    .comments {
      margin-top: 30px;
    }
    .time {
      margin-bottom: -20px;
      .dates {
        display: flex;
        justify-content: space-between;
        .date {
          width: calc(50% - 5px);
        }
      }
    }
    .specialization {
      .orderSkills {
        display: flex;
        flex-wrap: wrap;
        .skill {
          padding: 5px 10px;
          // background: #d6d6d6;
          margin: 5px 5px 0 0;
          display: flex;
        }

        .skillActive {
          background: #ff7303;
        }
      }

      .skills {
        margin-top: 5px;
        padding: 24px 12px;
        border: 1px solid rgba(0, 0, 0, 0.42);

        .title {
          font-size: 14px;
        }

        .allSkills {
          display: flex;
          flex-wrap: wrap;

          .skill {
            border: 1px solid #d6d6d6;
            user-select: none;
            cursor: pointer;
            padding: 5px 10px;
            margin: 5px 5px 0 0;

            &:hover {
              background: #d6d6d6;
            }
          }
          .skillActive {
            background: #d6d6d6;
          }
        }
      }
    }
    .price {
      .prices {
        display: flex;
        justify-content: space-between;
        .block {
          width: calc(50% - 5px);
        }
      }
    }
  }
}

.orderSkills {
  > div {
    display: flex;
    flex-wrap: wrap;

    .list-item {
      user-select: none;
      cursor: pointer;
      padding: 5px 10px;
      margin: 5px 5px 0 0;
      background: #d6d6d6;
      border-radius: 10px;
      display: flex;
      align-items: center;
    }

    .list-enter-active {
      background: #d6d6d6;
      transition: all 2s;
      * {
        transition: all 1s;
        color: #212121 !important;
      }
    }
    .list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */ {
      * {
        color: #fff !important;
      }
      background: #ff7303;
    }
  }
}
</style>
