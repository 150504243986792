<template>
  <v-dialog
    :value="isAddProjectModal"
    @click:outside="$emit('addProjectModal', false)"
  >
    <v-card class="modalAddProject" height="700">
      <v-form ref="form" v-model="valid">
        <p class="modal__title">Назначить сотрудника</p>
        <div class="mt-6">
          <v-combobox
            :items="getFilterProjects"
            label="Выберите проект"
            required
            :rules="nameRules"
            outlined
            rounded
            hide-details
            v-model="changeSelectedProject"
            item-value="id"
            item-text="value"
            class="rounded-lg mb-5"
          ></v-combobox>

          <v-combobox
            :items="getFilterOrders"
            :disabled="!selectedProject"
            label="Выберите заказ"
            required
            :rules="nameRules"
            outlined
            rounded
            hide-details
            v-model="selectedOrder"
            item-value="id"
            item-text="title"
            class="rounded-lg mb-5"
          ></v-combobox>

          <v-combobox
            :items="getFilterEmployees"
            label="Выберите сотрудника"
            required
            :rules="nameRules"
            outlined
            rounded
            hide-details
            v-model="selectedEmployee"
            item-value="id"
            item-text="value"
            class="rounded-lg mb-5"
          />

          <v-combobox
            :items="getFilterPosition"
            label="Выберите должность"
            required
            :rules="nameRules"
            outlined
            rounded
            hide-details
            v-model="selectedPosition"
            item-value="value"
            item-text="value"
            class="rounded-lg mb-5"
          ></v-combobox>

          <div class="modal__label">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon dense v-bind="attrs" v-on="on"
                  >mdi-information-outline</v-icon
                >
              </template>
              <span
                >Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span
              >
            </v-tooltip>
            <span>Период работы сотрудника</span>
          </div>
          <div class="dates">
            <div class="mr-2">
              <DatePicker
                class="dateOne"
                placeholder="Начало периода"
                :value="date_start"
                @changeDate="changeDateFirst"
              />
            </div>
            <div class="ml-2">
              <DatePicker
                placeholder="Конец периода"
                :value="date_end"
                @changeDate="changeDateSecond"
              />
            </div>
          </div>
          <v-select
            :items="times"
            label="Часы"
            required
            :rules="nameRules"
            outlined
            rounded
            hide-details
            v-model="selectedTime"
            item-value="id"
            item-text="title"
            class="rounded-lg mt-5"
          ></v-select>

          <div class="card-actions d-flex justify-center">
            <v-btn
              color="black"
              :dark="checkValid"
              :disabled="!checkValid"
              class="modal__btn mt-10"
              x-large
              @click="save"
            >
              Сохранить
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-card>

    <div class="bubles">
      <div class="buble bubleA"></div>
      <div class="buble bubleB"></div>
      <div class="buble bubleC"></div>
      <div class="buble bubleD"></div>
    </div>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import notification from '@/mixins/notification';
import DatePicker from '@/components/UI/DatePicker.vue';

export default {
  name: 'AddAppointEmployees',
  mixins: [notification],
  components: {
    DatePicker,
  },
  data() {
    return {
      valid: true,
      selectedProject: null,
      selectedOrder: null,
      selectedEmployee: null,
      selectedPosition: null,
      selectedTime: null,
      date_start: null,
      date_end: null,
    };
  },
  mounted() {
    // console.log('this.currentAppointEmployeesData', this.currentAppointEmployeesData);
    this.checkAppointEmployeesData();
  },

  computed: {
    ...mapState(['times', 'filters']),
    checkValid() {
      return !!(
        this.selectedProject && this.selectedOrder && this.selectedEmployee && this.selectedTime !== null && this.date_start && this.date_end
      );
    },

    changeSelectedProject: {
      get() {
        return this.selectedProject;
      },
      set(val) {
        console.log('val', val);
        this.selectedProject = val;
        this.selectedOrder = null;
      },
    },

    getFilterProjects() {
      return this.filters?.projects || [];
    },
    getFilterOrders() {
      if (!this.selectedProject) return [];
      return (
        this.filters?.projects.find((el) => el.id === this.selectedProject.id)
          .tasks || []
      );
    },
    getFilterEmployees() {
      const employees = this.filters?.employees || [];
      const pms = this.filters?.pms || [];

      return [...employees, ...pms].map((el) => ({
        id: el.id,
        value: el.value,
      }));
    },
    getFilterPosition() {
      return this.filters?.positions || [];
    },

    formattedDateStart: {
      get() {
        return this.getFormattedDate(this.date_start);
      },
      set(val) {
        this.date_start = val;
      },
    },
    formattedDateFinish: {
      get() {
        return this.getFormattedDate(this.date_end);
      },
      set(val) {
        this.date_end = val;
      },
    },
  },
  watch: {
    selectedOrder(nextVal) {
      console.log('selectedOrder', nextVal);
    },
    selectedEmployee(nextVal) {
      console.log('selectedEmployee', nextVal);
    },
    date_start(nextVal) {
      console.log('date_start', nextVal);
    },
    date_end(nextVal) {
      console.log('date_start', nextVal);
    },
  },

  methods: {
    checkValidDate() {
      return new Promise((resolve) => {
        this.isDatesPeriodValid(
          this.date_start,
          this.date_end,
          () => {
            this.date_end = '';
            this.showErrorNotification(
              'Дата окончания проекта введена некорректно',
            );
            resolve(false);
          },
          () => {
            resolve(true);
          },
        );
      });
    },
    async save() {
      if (await this.checkValidDate()) {
        this.$emit('addProject', {
          userId: this.selectedEmployee?.id || null,
          project: this.selectedProject?.id || null,
          task: this.selectedOrder?.id || null,
          position: this.selectedPosition?.value || null,
          time: this.selectedTime,
          date_start: this.date_start,
          date_end: this.date_end,
        });
        this.$refs.form.reset();
      }
    },
    changeDateFirst(val) {
      this.date_start = val;
    },

    changeDateSecond(val) {
      this.date_end = val;
    },
    checkAppointEmployeesData() {
      const keys = Object.keys(this.currentAppointEmployeesData);
      if (keys.length) {
        keys.forEach((key) => {
          if (key === 'project') {
            const prodjectIndex = this.getFilterProjects.findIndex(
              (prod) => prod.id === this.currentAppointEmployeesData[key].id,
            );
            if (prodjectIndex >= 0) {
              this.selectedProject = this.getFilterProjects[prodjectIndex];
            }

            const positionIndex = this.getFilterPosition.findIndex(
              (position) => position.value === this.currentAppointEmployeesData[key].position,
            );

            if (prodjectIndex >= 0) {
              this.selectedPosition = this.getFilterPosition[positionIndex];
            }
          }
        });
      }
    },
  },

  props: {
    isAddProjectModal: {
      type: Boolean,
      default: false,
    },
    currentAppointEmployeesData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  position: relative !important;
  overflow: visible;
  height: 700px;
  width: 450px;
}

.modalAddProject {
  height: 700px;
  border-radius: 16px;
  padding: 65px 45px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  overflow: hidden;

  .modal__title {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
  }

  .modal__label {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 6px;

    span {
      margin-left: 4px;
    }
  }

  .dates {
    display: flex;
  }
}

.bubles {
  z-index: 1;
  width: 550px;
  height: 800px;
  position: absolute;
  top: -50px;
  left: -50px;
  pointer-events: none;

  .buble {
    background: #ff7303;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
  }
  .bubleA {
    left: 25px;
    top: 110px;
    width: 50px;
    height: 50px;
  }
  .bubleB {
    top: 260px;
    right: 40px;
    width: 24px;
    height: 24px;
  }
  .bubleC {
    bottom: 110px;
    right: 100px;
    width: 12px;
    height: 12px;
  }
  .bubleD {
    bottom: 14px;
    right: 14px;
    width: 80px;
    height: 80px;
  }
}
</style>
