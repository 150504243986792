<template>
  <v-dialog
    :value="isSetClockDate"
    @click:outside="$emit('setClock', false)"
  >
    <v-card class="modal-container">
      <p class="modal__title mb-10">Установить загрузку</p>

      <v-select
        :items="workTypes"
        label="выберите вид загрузки"
        outlined
        rounded
        class="rounded-lg"
        v-model="selectedType"
        item-value="id"
        item-text="title"
      >
      </v-select>

      <v-select
        :items="times"
        label="Кол-во часов в день"
        outlined
        rounded
        class="rounded-lg"
        v-model="selectedTime"
        v-if="selectedType === 'work'"
      >
      </v-select>
      <div class="card-actions d-flex justify-center">
        <v-btn
          color="black"
          class="mt-8 modal__btn"
          dark
          x-large
          @click="save"
        >Сохранить</v-btn>
      </div>
    </v-card>

    <div class="bubles">
      <div class="buble bubleA"></div>
      <div class="buble bubleB"></div>
    </div>
  </v-dialog>
</template>

<script>
import { workTypes } from '@/core/constants/workTypes';

export default {
  name: 'SetClockModalDate',
  data() {
    return {
      selectedTime: 8,
      selectedType: 'work',
      workTypes,
      dates: [],
      times: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    };
  },

  computed: {
  },

  methods: {
    save() {
      this.$emit('setClockUsers', {
        type: this.selectedType,
        hour: this.selectedTime,
      });
      this.$emit('setClock', false);
    },
  },

  props: {
    isSetClockDate: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  position: relative !important;
  overflow: visible;
  width: 450px;
}

.bubles {
  z-index: 1;
  width: 550px;
  position: absolute;
  top: -50px;
  left: -50px;
  bottom: -50px;
  right: -50px;
  pointer-events: none;

  .buble {
    background: #ff7303;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
  }
  .bubleA {
    left: 35px;
    bottom: 10px;
    min-width: 80px;
    min-height: 80px;
  }
  .bubleB {
    left: 100px;
    bottom: 100px;
    min-width: 13px;
    min-height: 13px;
  }
}
</style>
