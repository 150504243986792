<template>
  <div class="description">
    <div
      class="top"
      @click="show = !show"
    >
      <span class="text">{{show ? "Скрыть проект": "Подробнее о проекте"}}</span>
      <v-icon color="#1976D2">{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
    </div>

    <v-expand-transition>
      <div v-show="show">
        <div class="content">
          <span
            v-for="(line,lineNumber) of text.split('\n')"
            :key="lineNumber"
          >{{ line }}<br /></span>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: 'ShowHideText',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: false,
    };
  },

  mounted() {
  },

  computed: {
    getValue() {
      return this.valueMain;
    },
  },

  methods: {

  },
};

</script>

<style scoped lang="scss">
.description {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  justify-content: flex-end;
  padding: 15px 20px;
  background: #f9f9f9;
  border: 1px solid rgba(0, 0, 0, 0.42);
  box-sizing: border-box;
  border-radius: 12px;
  .top {
    user-select: none;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    .text {
      color: #1976d2;
    }
  }
  .content {
    margin-top: 20px;
    font-size: 16px;
    color: #212121;
  }
}
</style>
