<template>
  <div class="plan">
    <template v-if="orderSpecialist.isOpen">
      <OrderSpecialist
        :orderSpecialist="orderSpecialist"
        @closeOrderSpecialist="closeOrderSpecialist"
      />
    </template>

    <template v-if="!orderSpecialist.isOpen">
      <h3 class="tit">Ресурсы</h3>
      <v-tabs class="tabs" v-model="changePlanTab">
        <v-tab>ПЛАН</v-tab>
        <v-tab>ФАКТ</v-tab>
      </v-tabs>
      <Filters
        :tableParams="tableParams"
        :isShowSetClock="checkedUsers.items.length > 0"
        @openAllListItems="openAllListItems"
        @changeFilters="changeFilters"
        @getOnlyActiveStaff="getOnlyActiveStaff"
        @changeDate="changeDate"
        @setClockUsers="changeClockCheckedUsers"
      />

      <Loader
        v-if="
          (loading && table.length === 0) ||
          (showTableLoader && table.planning.length === 0)
        "
      />

      <NoResultsTable
        v-else-if="table.planning && table.planning.length === 0"
        :showLabel="true"
        :labelText="'По данным из фильтров ничего не найдено!'"
      />

      <Table
        v-else
        ref="PlanTable"
        :usersProjectItemsChecked="checkedUsers.items"
        :planTab="planTab"
        :mouseIsPress="mouseIsPress"
        :showLoader="showTableLoader"
        @addProjectForUser="addProjectForUser"
        @addProjectForAbstract="addProjectForAbstract"
        @closeOrderSpecialist="closeOrderSpecialist"
        @openOrderSpecialist="openOrderSpecialist"
      />
    </template>
    <DeleteEmployeeModalVue
      :data="dialogData"
      :showDialog="isShowDialog"
      @deleteEmployee="deleteEmployee"
      @closeDialog="dialogAction"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import notification from '@/mixins/notification';
import { zeroPadded } from '@/core/helpers';
import Loader from '@/components/UI/Loader.vue';
import Filters from './components/Filters.vue';
import Table from './components/Table.vue';
import OrderSpecialist from './components/OrderSpecialist.vue';
import DeleteEmployeeModalVue from './components/DeleteEmployeeModal.vue';
import NoResultsTable from '@/components/UI/NoResultsTable.vue';

export default {
  name: 'PlanMain',
  mixins: [notification],
  components: {
    Filters,
    Table,
    Loader,
    OrderSpecialist,
    DeleteEmployeeModalVue,
    NoResultsTable,
  },
  computed: {
    ...mapState('plan', ['table', 'planTab']),
    changePlanTab: {
      get() {
        return this.planTab;
      },
      set(val) {
        this.setPlanTab(val);
      },
    },
  },
  data() {
    return {
      orderSpecialist: {
        isOpen: false,
        position: null,
        project: null,
      },
      mouseIsPress: false,
      loading: true,
      isShowSetClock: false,
      checkedUsers: {
        items: [],
        cell_type: 'work',
        cell_hour: 8,
      },
      tableParams: {
        type: 'day',
        day: [],
        quarter: '1',
        month: '0',
        year: `${new Date().getFullYear()}`,
        employee: null,
        project: null,
        position: null,
        is_outsource: null,
        employee_sord: null,
        position_sort: null,
        deviation: null,
        is_active: false,
      },
      showTableLoader: false,
      isShowDialog: false,
      dialogData: null,
    };
  },
  created() {
    // this.getPeriod()
    this.getTableData();
    this.getFilters();

    this.$emitter.$on('deleteEmployeeFromProject', (data) => {
      this.dialogData = data;
      this.dialogAction(true);
    });
  },
  mounted() {
    this.addListenerForMousePress();
  },
  methods: {
    ...mapActions('plan', [
      'getTable',
      'postWorkLoad',
      'postProjectForUser',
      'postProjectForAbstract',
      'deleteEmployeeFromProject',
    ]),
    ...mapActions(['getFilters']),

    ...mapMutations('plan', ['setPlanTab']),

    async getTableData() {
      this.loading = true;
      if (this.table.length !== 0) this.showTableLoader = true;

      await this.getTable({
        type: this.tableParams.type,
        day: this.getPeriodDate(),
        quarter: this.tableParams.quarter,
        month: String(Number(this.tableParams.month) + 1),
        year: this.tableParams.year,
        employee: this.tableParams.employee,
        project: this.tableParams.project,
        position: this.tableParams.position,
        is_outsource: this.tableParams.is_outsource,
        employee_sord: this.tableParams.employee_sord,
        position_sort: this.tableParams.position_sort,
        deviation: this.tableParams.deviation,
        is_active: this.tableParams.is_active,
      });

      this.checkedUsers.items = [];
      this.loading = false;
      this.showTableLoader = false;
    },
    async addProjectForUser(obj) {
      await this.postProjectForUser(obj);

      this.getTableData();
    },
    async addProjectForAbstract(obj) {
      await this.postProjectForAbstract(obj);
      this.getTableData();
    },
    getPeriod() {
      const date = new Date();
      const date7 = new Date(new Date().setDate(new Date().getDate() + 6));
      const dayFrom = `${date.getFullYear()}-${zeroPadded(
        date.getMonth() + 1,
      )}-${zeroPadded(date.getDate())}`;
      const dayTo = `${date7.getFullYear()}-${zeroPadded(
        date7.getMonth() + 1,
      )}-${zeroPadded(date7.getDate())}`;
      this.tableParams.day = [dayFrom, dayTo];
      return [dayFrom, dayTo];
    },

    getPeriodDate() {
      if (this.tableParams.day.length === 0) {
        this.getPeriod();
      }
      const dates = [];
      if (this.tableParams.day[0]) {
        const [year, month, day] = this.tableParams.day[0].split('-');
        dates.push(`${day}.${month}.${year}`);
        if (this.tableParams.day[1]) {
          const [year, month, day] = this.tableParams.day[1].split('-');
          dates.push(`${day}.${month}.${year}`);
        }
      }
      return dates;
    },

    changeDate(obj) {
      this.tableParams.day = obj.day;
      this.tableParams.is_active = false;
      this.getTableData();
    },
    changeFilters(obj) {
      this.showTableLoader = true;
      this.tableParams.is_active = false;
      this.tableParams.employee = obj.employee;
      this.tableParams.project = obj.project;
      this.tableParams.position = obj.position;
      this.tableParams.is_outsource = obj.employeeType;
      this.getTableData();
    },
    getOnlyActiveStaff(payload) {
      this.showTableLoader = true;
      this.tableParams.is_active = payload;
      this.getTableData();
    },
    async changeClockCheckedUsers(obj) {
      this.checkedUsers.cell_type = obj.type;
      this.checkedUsers.cell_hour = obj.hour;

      await this.postWorkLoad({
        ...this.checkedUsers,
        cell_hour:
          this.checkedUsers.cell_type === 'work'
            ? this.checkedUsers.cell_hour
            : 0,
        date_type: this.tableParams.type,
      });

      this.getTableData();
    },
    openAllListItems(val) {
      // eslint-disable-next-line no-unused-expressions,no-return-assign
      this.$refs?.PlanTable?.$refs?.ListItem?.forEach(
        // eslint-disable-next-line no-unused-expressions,no-return-assign
        (el) => (el.isSubItem = val),
      );
    },
    changeUsersChecked(obj) {
      this.isShowSetClock = obj.checkedItems.length > 0;
      this.checkedUsers.items = obj.checkedItems;
    },
    addListenerForMousePress() {
      window.addEventListener('mousedown', this.changeMousePress);
      window.addEventListener('mouseup', this.changeMousePress);
    },
    openOrderSpecialist(user) {
      this.orderSpecialist.position = user.position;
      this.orderSpecialist.project = user.project;
      // document.querySelector('.main').scrollTop = 0;
      this.orderSpecialist.isOpen = true;
    },
    closeOrderSpecialist() {
      this.orderSpecialist.isOpen = false;
    },
    changeMousePress(e) {
      if (e.type === 'mousedown') {
        this.mouseIsPress = true;
      } else {
        this.mouseIsPress = false;
      }
    },
    dialogAction(action) {
      this.isShowDialog = action;
    },
    async deleteEmployee(data) {
      const res = await this.deleteEmployeeFromProject(data);

      if (!res.status || res.status !== 200) {
        this.showErrorNotification('Произошла ошибка, попробуйте снова');
      }

      this.dialogAction(false);
      this.getTableData();
    },
  },

  beforeDestroy() {
    window.removeEventListener('mousedown', this.changeMousePress);
    window.removeEventListener('mouseup', this.changeMousePress);
  },
};
</script>

<style scoped lang="scss">
.plan {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 30px;
  overflow: auto;

  .tit {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
  }
  .tabs {
    margin-top: 10px;
    flex: none;
  }
}
.emptyList {
  margin: 0 auto;
  text-align: center;
  margin-top: 50px;
  font-size: 16px;
}
</style>
