<template>
  <v-dialog
    :value="isAddProjectModal"
    @click:outside="$emit('addProjectModal', false)"
  >

    <v-card
      class="modalAddProject"
      height="480"
    >
      <v-form
        ref="form"
        v-model="valid"
      >
        <p class="modal__title">Добавить проект</p>
        <div class="mt-6">
          <v-select
            :items="getFilterProjects"
            label="Выберите проект"
            required
            :rules="nameRules"
            outlined
            rounded
            hide-details
            v-model="selectedProject"
            item-value="id"
            item-text="value"
            class="rounded-lg mb-5"
          ></v-select>
          <div class="dates">
            <DatePicker
              class="dateOne"
              placeholder="Начало проекта"
              :value="date_start"
              @changeDate="changeDateFirst"
            />
            <div class="ml-2">
              <DatePicker
                placeholder="Окончание проекта"
                :value="date_end"
                @changeDate="changeDateSecond"
              />
            </div>
          </div>
          <v-select
            :items="times"
            label="Часы"
            required
            :rules="nameRules"
            outlined
            rounded
            hide-details
            v-model="selectedTime"
            item-value="id"
            item-text="title"
            class="rounded-lg mt-5"
          ></v-select>

          <div class="card-actions d-flex justify-center">
            <v-btn
              color="black"
              :dark="checkValid"
              :disabled="!checkValid"
              class="modal__btn mt-10"
              x-large
              @click="save"
            >
              Сохранить
            </v-btn>
          </div>

        </div>
      </v-form>

    </v-card>

    <div class="bubles">
      <div class="buble bubleA"></div>
      <div class="buble bubleB"></div>
      <div class="buble bubleC"></div>
      <div class="buble bubleD"></div>
    </div>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import notification from '@/mixins/notification';
import DatePicker from '@/components/UI/DatePicker.vue';

export default {
  name: 'AddProjectModalTwoCalendars',
  mixins: [notification],
  components: {
    DatePicker,
  },
  data() {
    return {
      valid: true,
      selectedProject: null,
      selectedTime: null,
      date_start: null,
      date_end: null,
    };
  },

  computed: {
    ...mapState(['times', 'filters']),
    checkValid() {
      return !!(this.selectedProject && this.selectedTime !== null && this.date_start && this.date_end);
    },
    getFilterProjects() {
      return this.filters?.projects || [];
    },
    formattedDateStart: {
      get() {
        return this.getFormattedDate(this.date_start);
      },
      set(val) {
        this.date_start = val;
      },
    },
    formattedDateFinish: {
      get() {
        return this.getFormattedDate(this.date_end);
      },
      set(val) {
        this.date_end = val;
      },
    },
  },

  methods: {
    checkValidDate() {
      return new Promise((resolve) => {
        this.isDatesPeriodValid(this.date_start, this.date_end, () => {
          this.date_end = '';
          this.showErrorNotification('Дата окончания проекта введена некорректно');
          resolve(false);
        }, () => {
          resolve(true);
        });
      });
    },
    async save() {
      if (await this.checkValidDate()) {
        this.$emit('addProject', {
          project: this.selectedProject,
          time: this.selectedTime,
          date_start: this.date_start,
          date_end: this.date_end,
        });
        this.$refs.form.reset();
      }
    },
    changeDateFirst(val) {
      this.date_start = val;
    },

    changeDateSecond(val) {
      this.date_end = val;
    },
  },

  props: {
    isAddProjectModal: {
      type: Boolean,
      default: false,
    },
  },

};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  position: relative !important;
  overflow: visible;
  height: 480px;
  width: 450px;
}

.modalAddProject {
  height: 480px;
  border-radius: 16px;
  padding: 65px 45px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  height: 480px;
  overflow: hidden;

  .modal__title {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
  }

  .dates {
    display: flex;
  }
}

.bubles {
  z-index: 1;
  width: 550px;
  height: 580px;
  position: absolute;
  top: -50px;
  left: -50px;
  pointer-events: none;

  .buble {
    background: #ff7303;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
  }
  .bubleA {
    left: 25px;
    top: 110px;
    width: 50px;
    height: 50px;
  }
  .bubleB {
    top: 260px;
    right: 40px;
    width: 24px;
    height: 24px;
  }
  .bubleC {
    bottom: 110px;
    right: 100px;
    width: 12px;
    height: 12px;
  }
  .bubleD {
    bottom: 14px;
    right: 14px;
    width: 80px;
    height: 80px;
  }
}
</style>
