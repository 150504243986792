<template>
  <v-dialog :value="showDialog" width="520" @click:outside="$emit('closeDialog', false)">
    <div class="circle circle--middle"></div>
    <div class="circle circle--mini"></div>
    <div class="circle circle--big"></div>
    <v-card class="modal-container" height="327">
      <p class="modal__title text-center">Удалить?</p>

      <p class="modal__sub-title text-center">
        Вы уверены, что хотите удалить? <br />
        Действие не может быть отменено.
      </p>

      <v-card-actions class="d-flex justify-center">
        <v-btn
          color="#AAAAAA"
          class="mt-10"
          x-large
          outlined
          width="188"
          rounded
          @click="$emit('closeDialog', false)"
        >
          Отменить
        </v-btn>
        <v-btn
          color="#000"
          class="mt-10"
          x-large
          width="188"
          rounded
          @click="$emit('deleteEmployee', data)"
        >
          <div class="font-weight-bold white--text">Удалить</div>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteEmployeeModal',

  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog {
  position: relative !important;
  overflow: visible;
}

.circle {
  background: #ff7303;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  &--middle {
    width: 50px;
    height: 50px;
    left: 18px;
    top: -25px;
  }
  &--big {
    width: 78px;
    height: 78px;
    left: 491px;
    top: 279px;
  }
  &--mini {
    width: 10px;
    height: 10px;
    left: 482px;
    top: 254px;
  }
}

.col-item {
  width: 49%;
}

.modal__title {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
}

.modal__sub-title {
  font-size: 16px;
  color: #212121;
  line-height: 19px;
  margin-top: 32px;
}

.staff-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.staff-list__item {
  width: 49%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  & .v-input {
    max-width: 190px;
  }
}
</style>
