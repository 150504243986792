<template>
  <div>
    <div
      @click="isOpen = true"
      class="filterButton"
    >
      <v-icon>mdi-filter</v-icon>
      Фильтр
    </div>

    <v-dialog
      :value="isOpen"
      width="780"
      @click:outside="isOpen = false"
    >
      <v-card class="modal-container">
        <p class="modal__title">Фильтр</p>
        <v-form
          ref="form"
          class="mt-9"
        >
          <div class="row mb-3">
            <div class="col-sm-6 py-0">
              <v-combobox
                :items="getItemsForEmployees"
                label="Выберите сотрудника"
                outlined
                rounded
                hide-details
                v-model="selectedEmployes"
                item-value="id"
                item-text="value"
                class="rounded-lg" />
            </div>
            <div class="col-sm-6 py-0">
              <v-combobox
                :items="getFilterProjects"
                label="Выберите проект"
                outlined
                rounded
                hide-details
                v-model="selectedProject"
                item-value="id"
                item-text="value"
                class="rounded-lg"
              ></v-combobox>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 py-0">
              <v-combobox
                :items="getFilterPositions"
                label="Выберите должность"
                outlined
                rounded
                hide-details
                v-model="selectedPosition"
                item-value="value"
                item-text="value"
                class="rounded-lg"
              ></v-combobox>
            </div>

            <div class="col-sm-6 py-0">
              <v-combobox
                :items="employeeTypes"
                label="Выберите тип сотрудников"
                outlined
                rounded
                hide-details
                v-model="selectedEmployeeType"
                item-value="value"
                item-text="label"
                class="rounded-lg"
              ></v-combobox>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6 py-0">
              <v-btn
                color="black"
                dark
                class="rounded-lg mt-7"
                style="width: 100%"
                x-large
                outlined
                @click="reset"
              >
                <v-icon
                  size="big"
                  color="#AAAAAA"
                  class="mr-1"
                >mdi-filter-remove</v-icon>
                <span style="color: #AAAAAA">Сбросить фильтр</span>
              </v-btn>
            </div>

            <div class="col-sm-6 py-0">
              <v-btn
                color="black"
                dark
                style="width: 100%"
                class="rounded-lg mt-7"
                x-large
                @click="save"
              >
                Сохранить
              </v-btn>
            </div>

          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PlanFilter',

  data() {
    return {
      isOpen: false,
      selectedEmployes: null,
      selectedProject: null,
      selectedPosition: null,
      selectedEmployeeType: null,
      getUsersList: [],
      projects: [],
      positions: [],
      employeeTypes: [
        { value: null, label: 'Все' },
        { value: false, label: 'Штат' },
        { value: true, label: 'Аутстаф' },
      ],
    };
  },
  computed: {
    ...mapState(['filters']),
    getItemsForEmployees() {
      if (this.filters === null) return;
      return this.filters.pms.concat(this.filters.employees);
    },
    getFilterProjects() {
      return this.filters?.projects || [];
    },
    getFilterPositions() {
      return this.filters?.positions || [];
    },
  },

  methods: {
    reset() {
      this.selectedEmployes = null;
      this.selectedProject = null;
      this.selectedPosition = null;
      this.selectedEmployeeType = null;

      this.save();
    },
    save() {
      this.$emit('changeFilters', {
        employee: this.selectedEmployes?.id || null,
        project: this.selectedProject?.id || null,
        position: this.selectedPosition?.value || null,
        employeeType: this.selectedEmployeeType !== null ? this.selectedEmployeeType.value : null,
      });
      this.isOpen = false;
    },
  },

  props: {
    isFilterModal: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.filterButton {
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}
</style>
